<template>
  <div class="form-group form-group-sm">
    <label
      class="clicable"
      for="pending_command_sel"
      @click.stop.prevent="visible = !visible"
    >
      {{ $t("command_status") }}
      <i :class="visible ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"></i>
    </label>
    <select
      v-if="visible"
      id="pending_command_sel"
      class="form-control form-group-sm"
      v-model="control.synopticComponent.commandStatus"
    >
      <option
        v-for="(item, ix) in items"
        v-bind:key="ix"
        v-bind:value="item.id"
        >{{ $tc(item.title, 1) }}</option
      >
    </select>
  </div>
</template>

<script>
export default {
  name: "DataValueCommandStatus",
  props: {
    control: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  data() {
    return {
      items: [
        { title: "none", id: "none" },
        { title: "top_left", id: "top-left" },
        { title: "top_right", id: "top-right" },
        { title: "top_center", id: "top-center" },
        { title: "middle_left", id: "middle-left" },
        { title: "middle_right", id: "middle-right" },
        { title: "middle_center", id: "middle-center" },
        { title: "bottom_left", id: "bottom-left" },
        { title: "bottom_right", id: "bottom-right" },
        { title: "bottom_center", id: "bottom-center" }
      ],
      visible: false
    };
  }
};
</script>

<style scoped>
.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}
</style>
