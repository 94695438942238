<template>
  <div class="form-inline">
    <div class="form-group form-group-sm">
      <label
        class="label-inline"
        for="editor-style-background"
        style="min-width:100px;"
      >
        {{ label }}
      </label>
      <ColorPicker
        class="color-picker"
        v-bind:title="$t('synoptic.text_color')"
        v-model="style['color']"
        icon="fa fa-font"
        data-testid="color"
      />
      <ColorPicker
        class="color-picker"
        v-bind:title="$t('synoptic.background_color')"
        v-model="style['background-color']"
        :pickerStyle="{ left: '-121px' }"
        icon="background"
        style="display:inline-block;vertical-align:bottom;"
        data-testid="background-color"
      />
    </div>
  </div>
</template>

<script>
import { isEqual } from "lodash";
import ColorPicker from "@/components/editor/color-picker";
const initialState = () => ({
  style: {
    color: "black",
    "background-color": "transparent"
  }
});

export default {
  name: "MultiColorsSelector",
  components: {
    ColorPicker
  },
  props: {
    value: {
      type: Object,
      required: false
    },
    label: {
      type: String,
      required: false,
      default: function() {
        this.$t("synoptic.colors");
      }
    }
  },
  data: () => initialState(),
  watch: {
    style: {
      handler(n) {
        if (!n || isEqual(n, this.value)) return;
        this.$emit("input", n);
      },
      deep: true
    },
    value: {
      handler(n) {
        if (isEqual(n, this.style)) return;
        this.style = JSON.parse(JSON.stringify(n || initialState().style));
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style scoped>
.color-picker {
  display: inline-block;
  vertical-align: bottom;
  margin-left: 5px;
}
</style>
