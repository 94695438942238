import { render, staticRenderFns } from "./multi-colors-selector.vue?vue&type=template&id=197895c4&scoped=true&"
import script from "./multi-colors-selector.vue?vue&type=script&lang=js&"
export * from "./multi-colors-selector.vue?vue&type=script&lang=js&"
import style0 from "./multi-colors-selector.vue?vue&type=style&index=0&id=197895c4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "197895c4",
  null
  
)

export default component.exports